<template>
  <section>
      <!-- <h4 class="app-stepper-title">Criar pedido de Autovistoria para a empresa com CNPJ abaixo?</h4> -->

      <v-row no-gutters>
        <v-col md="12" sm="12" xs="12">
          <v-autocomplete
            label="Placa*"
            placeholder="Digite o Placa do veículo que deseja fazer autovistoria"
            prepend-inner-icon="directions_bus"
            outlined
            clearable
            color="success"
            v-model="veiculoForm.placas"
            :items="veiculos"
            multiple
            chips
            deletable-chips
            class="custom-autocomplete m-0"
            :error="error.active"
            :error-messages="error.msg"
            @input="verifyForm"
            :loading="loader"
            id="placas"
            @keydown="
              (e) => {
                addNewPlaca(e);
              }
            "
            open-delay="500"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <section class="loading" v-if="form.isLoading">
        <v-progress-circular
          indeterminate
          color="success"
        ></v-progress-circular>
        <span>Carregando informações...</span>
      </section>
    <v-card-actions class="ml-0 mr-0">
      <v-spacer></v-spacer>
      <v-btn color="success" depressed type="btn" @click="onSubmit"
        >Continuar</v-btn
      >
    </v-card-actions>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as _ from "lodash";
import _debounce from "lodash/debounce";

import VeiculosService from "@/services/veiculos.service";
import TipoPedidoService from "@/services/tipo-pedidos.service";
import { TipoPedido } from "@/models/tipo.enum";
import GlobalStore from "@/store/modules/global";

export default {
  name: "RenovacaoRegistroVeiculoForm",
  data: () => ({
    veiculoForm: { placas: [] },
    enteredText: "",
    veiculos: [],
    rules: {
      required: (value) => !!value || "Esse campo deve ser preenchido",
    },
    textNewPlaca: "",
    seeNewPlaca: false,
    error: { active: false, msg: "" },
    loader: false,
    placa: "",
  }),
  methods: {
    onSubmit() {
      if (this.veiculoForm.placas.length > 0) {
        this.createPedidos(this.veiculoForm);
      }
      this.verifyForm(this.veiculoForm.placas);
    },
    clearVeiculoForm() {
      this.veiculoForm = {
        placa: null,
      };
    },
    loadCurrentSolicitationType() {
      // this.searchVeiculosForm.isLoading = true;
      const query = {
        input: {
          key: "AUTOVISTORIA_LAUDO_ENGENHEIRO_MECANICO",
        },
        inputPage: {
          page: 1,
          size: 1,
          order: "id",
          descendingOrder: true,
        },
      };
      TipoPedidoService.page(query).subscribe(
        ({ items }) => {
          // this.searchVeiculosForm.isLoading = false;
          if (items.length) {
            this.veiculoForm.typeSolicitation = items;
          }
        },
        (err) => {
          // this.searchVeiculosForm.isLoading = false;
        }
      );
    },
    searchPlacas() {
      this.loader = true;
      const query = {
        input: {
          placa: "",
          chassiNumero: "",
        },
        inputPage: {
          page: 1,
          size: 20,
          order: "name",
          descendingOrder: true,
        },
      };
      VeiculosService.page(query).subscribe((res) => {
        const { items } = res;
        items.forEach((veiculos) => {
          this.veiculos.push(veiculos.veiculo.placa);
        });
        this.loader = false;
      }),
        (err) => {
          this.loader = false;
        };
    },
    handleInput() {
      // Update the enteredText with the current value
      this.enteredText = this.veiculoForm.placa;

      // Call the debounced function
      this.debouncedCheckAndAddItem();
    },
    ...mapActions("autovistoria", {
      createPedidos: "createPedidos",
    }),
    ...mapActions("autovistoria", {
      savePlacas: "savePlacas",
    }),
    verifyForm(e) {
      this.seeNewPlaca = e;
      if (e.length > 0) {
        this.error.active = false;
        this.error.msg = "";
      } else {
        this.error.active = true;
        this.error.msg = "Esse campo deve ser preenchido";
      }
    },
    addNewPlaca(e) {
      if (event.keyCode === 13 && e.target.value != "") {
        this.veiculos.push(e.target.value.toUpperCase());
        this.veiculoForm.placas.push(e.target.value.toUpperCase());
        this.verifyForm(this.veiculoForm.placas);
        e.target.value = "";
      }
    },
  },
  computed: {
    ...mapState("autovistoria", ["form"]),
    user: () => GlobalStore.user || {},
  },
  watch: {
    // verifica se muda o step para limpar os campos do form
    "form.currentStep": function (newValue, oldValue) {
      if (newValue) {
        this.clearVeiculoForm();
      }
    },
  },
  mounted() {
    this.searchPlacas();
    this.loadCurrentSolicitationType();
  },
};
</script>

<style lang="scss">
.veiculos-list {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  flex-wrap: wrap;
  flex-direction: row;

  &__item {
    padding: 0px 5px;
    flex: 1;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #999;
    cursor: pointer;

    &:hover,
    &--active {
      background: #067b26;
      color: #fff;
      .veiculos-list__icon {
        color: #fff;
      }
    }
  }

  &__placa {
    text-align: center;
    font-weight: bold;
  }
}
.notfound {
  text-align: center;
  margin: 0px;
}
</style>
