<template>
  <v-row class="mt-4">
    <v-col :lg="12" :sm="12" :md="12">
      <h4 class="app-stepper-title">Faça o upload dos arquivos</h4>
    </v-col>
    <v-col>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel
          v-for="(veiculo, indexVeiculo) in form.veiculosDocumentos"
          :key="veiculo.id"
        >
          <!-- {{ veiculo[veiculo.placa] }} -->
          <v-expansion-panel-header>
            <h5 class="p-0 font-weight-medium">
              <!-- <v-icon size="24" color="#e57878" v-if="!missingFiles"
                >info</v-icon
              > -->
              Placa:
              {{ veiculo.placa }}
            </h5>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="form" @submit.prevent="onSubmit">
              <v-col
                :lg="12"
                :sm="12"
                :md="12"
                v-for="(doc, indexDocs) in veiculo.documentos"
                :key="doc.typeDocumentKey"
              >
                <h4 class="typeDocument-title">
                  {{ doc.typeDocumentName }}
                </h4>
                <p class="typeDocument-subtitle">
                  {{ doc.typeDocumentDescription }}
                </p>
                <section class="d-flex justify-space-between align-center p-0">
                  <v-file-input
                    :id="`${indexDocs}` + `${indexVeiculo}`"
                    :name="`${indexDocs}` + `${indexVeiculo}`"
                    :value="doc.file"
                    @change="
                      onUpload($event, doc, indexVeiculo, indexDocs, veiculo.id)
                    "
                    :show-size="true"
                    color="green"
                    outlined
                    class="mb-0 p-0"
                    placeholder="selecione um arquivo"
                    :rules="[rules.required]"
                    accept=".pdf"
                    :max-size="5120"
                    prepend-icon="picture_as_pdf"
                  />
                  <section class="mb-8 pl-3">
                    <v-icon size="24" color="green" v-if="doc.uploaded"
                      >check_circle</v-icon
                    >
                    <v-icon size="24" color="#e57878" v-if="doc.error"
                      >info</v-icon
                    >
                    <v-progress-circular
                      size="21"
                      :width="3"
                      :value="doc.progress"
                      v-if="!doc.error && !doc.uploaded"
                    ></v-progress-circular>
                  </section>
                </section>
                <!-- <section class="loading" v-if="form.isLoading">
                  <v-progress-circular
                    indeterminate
                    color="success"
                  ></v-progress-circular>
                  <span>Enviando informações...</span>
                </section> -->
                <v-divider class="mt-6"></v-divider>
              </v-col>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col :lg="12" :sm="12" :md="12">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!verifyLastDoc(form.veiculosDocumentos)"
          color="success"
          depressed
          type="button"
          @click="onSubmit"
          :loading="loadActive"
          >Continuar</v-btn
        >
      </v-card-actions>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { fileSize } from "@/util/format-file";
import TipoDocumento from "@/services/tipo-documento.service";
import VeiculosService from "@/services/veiculos.service";

export default {
  name: "RenovacaoRegistroVeiculoDocumentosForm",
  components: {},
  data: () => ({
    documentPagination: {
      input: {
        name: null,
        typeSolicitationId: null,
      },
      inputPage: {
        page: 1,
        size: 20,
        order: "name",
        descingOrder: false,
      },
    },
    rules: {
      required: (value) => !!value || "Campo obrigatorio, selecione um arquivo",
    },
    docsVeiculos: [],
    documentos: [],
    panel: [],
    missingFiles: true,
    loadActive: false,
  }),
  methods: {
    onSubmit() {
      // const isValid = this.$refs.form.validate();
      // this.missingFiles = isValid;
      this.loadActive = true;
      this.changeStepForm(3);
      this.loadActive = false;
    },
    fileSize(v) {
      return fileSize(v);
    },
    onUpload(event, doc, indexVeiculo, indexDocs, id) {
      this.handleInput(event, indexDocs, indexVeiculo, id);
      this.uploadDocument({
        currentDocument: doc,
        file: doc,
        id,
      });
    },
    findAllTypeDocuments() {
      this.pageTipoDocumento(this.documentPagination);
    },
    ...mapActions("autovistoria", {
      pageTipoDocumento: "findTypesDocuments",
    }),
    ...mapActions("autovistoria", {
      uploadDocument: "uploadDocument",
    }),
    ...mapActions("autovistoria", {
      changeStepForm: "changeStepForm",
    }),
    ...mapActions("autovistoria", {
      addDocumentos: "addDocumentos",
    }),
    updateDoc(event) {
      this.$store.commit("SET_FORM_DOC", []);
    },

    handleInput(documento, indexDocs, indexVeiculo, pedidoId) {
      this.form.veiculosDocumentos[indexVeiculo].documentos =
        this.form.veiculosDocumentos[indexVeiculo].documentos.map(
          (n, index) => {
            if (index === indexDocs) {
              return {
                ...n,
                solicitation: pedidoId,
                file: documento,
                filename: documento.name,
                type: documento.type,
                size: documento.size,
              };
            } else {
              return n;
            }
          }
        );
    },
    verifyLastDoc() {
      const result = this.form.veiculosDocumentos.map((res) => {
        return res.documentos.every(function (doc, index) {
          return doc.uploaded === true;
        });
      });

      const verify = result.every(function (e) {
        return e === true;
      });
      return verify;
    },
  },
  computed: {
    ...mapState("autovistoria", ["form"]),
    hasGoNextStep: function () {
      return this.form.documentos.every((d) => d.uploaded && !d.error);
    },
    currentSolicitationType: function () {
      return this.form.data ? this.form.data : null;
    },
  },
  watch: {
    currentSolicitationType: function (newValue, oldValue) {
      if (newValue) {
        this.documentPagination.input.typeSolicitationId = parseInt(
          newValue.id
        );

        this.findAllTypeDocuments();
      }
    },
    "form.placas": function (newValue, oldValue) {
      this.findAllTypeDocuments();
    },
    "form.veiculosDocumentos": function (newValue, oldValue) {},
  },
  mounted() {
    // this.findAllTypeDocuments();
  },
};
</script>
