<template>
  <section>
    <PaymentForm
      :paymentProducts="paymentProducts"
      :paymentProductValue="paymentProductValue"
      :paymentProductOwnerIdentification="paymentProductOwnerIdentification"
      :idPedidos="idPedidos"
      @doPayment="doPayment"
    />
    <section class="loading" v-if="form.isLoading">
      <v-progress-circular indeterminate color="success"></v-progress-circular>
      <span>Enviando informações...</span>
    </section>
  </section>
</template>

<script>
import PaymentForm from "@/components/PaymentGroup/PaymentForm.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "RenovacaoRegistroVeiculoPagamentoForm",
  components: {
    PaymentForm,
  },
  methods: {
    doPayment(value) {
      this.doPayment(value);
    },
    ...mapActions("autovistoria", { doPayment: "doPayment" }),
  },
  computed: {
    ...mapState("autovistoria", ["form"]),
    ...mapState("global", ["user"]),

    paymentProducts: function () {
      return this.form.veiculosDocumentos ? this.form.veiculosDocumentos : [];
    },
    paymentProductValue: function () {
      return this.form.data ? Number(this.form.data.value) : 0;
    },
    paymentProductOwnerIdentification: function () {
      return this.user ? this.user.cnpj : "";
    },
    idPedidos: function () {
      return this.form.veiculosDocumentos.map((pedidos) => pedidos.id);
    },
  },
};
</script>
